body {
  color: #555;
  font-family: Open Sans, sans-serif;
  overflow-x: hidden;
}

a {
  color: #7cc576;
  text-decoration: none;
}

a:hover {
  color: #9ed49a;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
}

.back-to-top {
  visibility: hidden;
  opacity: 0;
  z-index: 996;
  background: #7cc576;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  transition: all .4s;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.back-to-top i {
  color: #fff;
  font-size: 28px;
  line-height: 0;
}

.back-to-top:hover {
  color: #fff;
  background: #97d193;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

@media screen and (width <= 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

#header {
  z-index: 997;
  background: #fff;
  height: 90px;
  transition: all .5s;
  box-shadow: 0 4px 10px -3px #bfbfbf80;
}

#header .logo img {
  max-height: 40px;
  margin: 0;
  padding: 0;
}

@media (width <= 992px) {
  #header {
    height: 70px;
  }
}

.scrolled-offset {
  margin-top: 90px;
}

@media (width <= 992px) {
  .scrolled-offset {
    margin-top: 90px;
  }
}

.navbar {
  padding: 0;
}

.navbar ul {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.navbar li {
  position: relative;
}

.navbar a, .navbar a:focus {
  color: #151515;
  text-transform: uppercase;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  transition: all .3s;
  display: flex;
}

.navbar a i, .navbar a:focus i {
  background-color: red;
  margin-left: 5px;
  font-size: 12px;
  line-height: 0;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #7cc576;
}

.navbar .dropdown ul {
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  margin: 0;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  left: 14px;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  text-transform: none;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
}

.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #7cc576;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.navbar .dropdown .dropdown ul {
  visibility: hidden;
  top: 0;
  left: calc(100% - 30px);
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

@media (width <= 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #151515;
  cursor: pointer;
  font-size: 28px;
  line-height: 0;
  transition: all .5s;
  display: none;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (width <= 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  z-index: 999;
  background: #000000e6;
  transition: all .3s;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  background-color: #fff;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 55px 15px 15px;
  overflow-y: auto;
}

.navbar-mobile a {
  color: #151515;
  padding: 10px 20px;
  font-size: 15px;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #7cc576;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  margin: 10px 20px;
  padding: 10px 0;
  display: none;
  position: static;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #7cc576;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

#hero {
  background: url("perth_hero.1bdd32bd.jpg") 0 0 / cover;
  width: 100%;
  height: 100vh;
  position: relative;
}

#hero .hero-container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  display: flex;
  position: absolute;
  inset: 0;
}

#hero .hero-logo {
  margin-bottom: 30px;
}

#hero h1 {
  color: #fff;
  margin: 0 0 30px;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
}

#hero .btn-come-in {
  letter-spacing: 1px;
  color: #fff;
  background: #7cc576;
  border-radius: 3px;
  margin: 10px;
  padding: 14px 30px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

#hero .btn-come-in:hover {
  color: #7cc576;
  background: #fff;
  transition: all .5s;
}

@media (width >= 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (width <= 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    line-height: 22px;
  }
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f5f5;
}

.section-title {
  text-align: center;
  padding-bottom: 40px;
}

.section-title h2 {
  text-transform: uppercase;
  color: #151515;
  margin-bottom: 15px;
  padding-bottom: 0;
  font-size: 32px;
  font-weight: bold;
}

.section-title p {
  color: #aeaeae;
  margin-bottom: 0;
}

.about .image {
  box-shadow: 0 2px 15px #0000001a;
}

.about .image img {
  border-radius: 7px;
}

.about .content h3 {
  font-size: 26px;
  font-weight: 600;
}

.skills .content {
  padding: 20px;
}

.skills .content ul {
  padding: 0;
  list-style: none;
}

.skills .content ul li {
  align-items: flex-start;
  padding-bottom: 10px;
  display: flex;
}

.skills .content ul i {
  color: #7cc576;
  padding: 2px 6px 0 0;
  font-size: 24px;
}

.skills .image {
  box-shadow: 0 2px 15px #0000001a;
}

.skills .image img {
  border-radius: 7px;
}

.experience .experience-list {
  padding: 0;
  list-style: none;
}

.experience .experience-list li {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.experience .experience-list li a {
  font-family: #7cc576;
  cursor: pointer;
  padding-right: 25px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  display: block;
  position: relative;
}

.experience .experience-list .title-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.experience .experience-list .title-row span.position {
  font-size: 1.2rem;
  font-weight: 600;
}

.experience .experience-list .title-row span.duration {
  font-size: 1rem;
}

.experience .experience-list .company-row {
  color: #aeaeae;
  margin-bottom: 5px;
  font-size: 1rem;
}

.experience .experience-list i {
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.experience .experience-list p {
  margin-bottom: 0;
  padding: 10px 0 0;
}

.experience .experience-list .icon-show {
  display: none;
}

.experience .experience-list a.collapsed {
  color: #343a40;
}

.experience .experience-list a.collapsed:hover, .experience .experience-list a.collapsed:hover .company-row {
  color: #7cc576;
}

.experience .experience-list a.collapsed .icon-show {
  display: inline-block;
}

.experience .experience-list a.collapsed .icon-close {
  display: none;
}

.education .edu-box {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding: 20px 20px 10px;
}

.education-list .edu-box:first-child {
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.education .edu-box div {
  float: left;
}

.education .edu-box div img {
  vertical-align: top;
  height: 48px;
  transition: transform .3s ease-in-out;
  transform: scale(1);
}

.education .edu-box:hover div img {
  transform: scale(1.1);
}

.education .edu-box h4 {
  margin: 5px 0 5px 72px;
  font-size: 20px;
  font-weight: 700;
}

.education .edu-box p {
  color: #959595;
  margin-left: 72px;
  font-size: 15px;
}

.education .edu-box:hover {
  background-color: #f5f5f5;
}

.education .image-container {
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: relative;
  overflow: hidden;
}

.education .image-container img {
  object-fit: cover;
  border-radius: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact .info-img {
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: relative;
  overflow: hidden;
}

.contact .info-img img {
  object-fit: cover;
  filter: hue-rotate(270deg) opacity(80%);
  border-radius: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact .info {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.contact .info .info-box {
  margin-top: 25px;
  margin-left: 20px;
}

.contact .info .info-box i {
  color: #7cc576;
  float: left;
  background: #eaf6e9;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  font-size: 20px;
  transition: all .3s ease-in-out;
  display: flex;
}

.contact .info .info-box h4 {
  color: #151515;
  margin-bottom: 5px;
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
}

.contact .info .info-box p {
  color: #484848;
  margin-bottom: 0;
  padding: 0 0 0 60px;
  font-size: 14px;
}

.contact .info .info-box p span {
  color: #9ed49a;
}

.contact .info .info-box:last-child {
  margin-bottom: 25px;
}

.contact .info .info-box:hover i {
  color: #fff;
  background: #7cc576;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#footer {
  color: #fff;
  font-size: 14px;
  position: relative;
}

#footer:before {
  content: "";
  z-index: 1;
  background: #000000d9;
  position: absolute;
  inset: 0;
}

#footer .footer-bottom {
  z-index: 2;
  border-top: 1px solid #222;
  padding-top: 30px;
  padding-bottom: 40px;
  position: relative;
}

#footer .copyright {
  text-align: center;
}

#footer .version {
  text-align: center;
  opacity: .3;
  font-size: 10px;
}
/*# sourceMappingURL=index.a0876a1a.css.map */
