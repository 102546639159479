/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #555555;
    overflow-x: hidden;
  }
  
  a {
    text-decoration: none;
    color: #7cc576;
  }
  
  a:hover {
    color: #9ed49a;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #7cc576;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #97d193;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable AOS delay on mobile
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height: 90px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: #FFF;/*EAEAEA;*/
    box-shadow: 0 4px 10px -3px rgba(191, 191, 191, 0.5);
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
  }
  
  @media (max-width: 992px) {
    #header {
      height: 70px;
    }
  }
  
  .scrolled-offset {
    margin-top: 90px;
  }
  
  @media (max-width: 992px) {
    .scrolled-offset {
      margin-top: 90px;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    color: #151515;
    text-transform: uppercase;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;background-color:red;
  }
  
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #7cc576;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
  }
  
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #7cc576;
  }
  
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #151515;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #151515;
  }
  
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #7cc576;
  }
  
  .navbar-mobile .getstarted {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #7cc576;
  }
  
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 100vh;
    background: url("../img/perth_hero.jpg");
    background-size: cover; /* Ensures the background image covers the element */
    position: relative;
  }
  
  #hero .hero-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
  }
  
  #hero .hero-logo {
    margin-bottom: 30px;
  }
  
  #hero h1 {
    margin: 0 0 30px 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: #fff;
  }
  
  #hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
  }
  
  #hero .btn-come-in {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 30px;
    border-radius: 3px;
    margin: 10px;
    color: #fff;
    background: #7cc576;
  }
  
  #hero .btn-come-in:hover {
    transition: 0.5s;
    background: #fff;
    color: #7cc576;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      line-height: 22px;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: whitesmoke;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding-bottom: 0;
    color: #151515;
  }
  
  .section-title p {
    margin-bottom: 0;
    color: #aeaeae;
  }
  
  /*--------------------------------------------------------------
  # About Me
  --------------------------------------------------------------*/
  .about .image {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .about .image img {
    border-radius: 7px;
  }
  
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  /*--------------------------------------------------------------
  # Skills
  --------------------------------------------------------------*/
  .skills .content {
    padding: 20px;
  }
  
  .skills .content ul {
    list-style: none;
    padding: 0;
  }
  
  .skills .content ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
  }
  
  .skills .content ul i {
    font-size: 24px;
    padding: 2px 6px 0 0;
    color: #7cc576;
  }
  
  .skills .image {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .skills .image img {
    border-radius: 7px;
  }
  
  
  /*--------------------------------------------------------------
  # Experience
  --------------------------------------------------------------*/
  .experience .experience-list {
    padding: 0;
    list-style: none;
  }
  
  .experience .experience-list li {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .experience .experience-list li a {
    display: block;
    position: relative;
    font-family: #7cc576;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding-right: 25px;
    cursor: pointer;
    text-decoration: none; /* added */
  }
  
  .experience .experience-list .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }
  
  .experience .experience-list .title-row span.position {
    font-size: 1.2rem;
    font-weight:600;
  }
  
  .experience .experience-list .title-row span.duration {
    font-size: 1rem;
  }
  
  .experience .experience-list .company-row {
    font-size: 1rem;
    margin-bottom: 5px;
    color:#aeaeae;
  }
  
  .experience .experience-list i {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .experience .experience-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .experience .experience-list .icon-show {
    display: none;
  }
  
  .experience .experience-list a.collapsed {
    color: #343a40;
  }
  
  .experience .experience-list a.collapsed:hover {
    color: #7cc576;
  }
  
  .experience .experience-list a.collapsed:hover .company-row {
    color: #7cc576;
  }
  
  .experience .experience-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .experience .experience-list a.collapsed .icon-close {
    display: none;
  }
  
  /*--------------------------------------------------------------
  # Education
  --------------------------------------------------------------*/
  .education .edu-box {
    border-bottom: 1px solid #eee;
    margin-bottom: 0px;
    padding-bottom: 10px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .education-list .edu-box:first-child {
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
  
  .education .edu-box div {
    float: left;
  }
  
  .education .edu-box div img {
    height: 48px;
    vertical-align: top;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    transition: transform 0.3s ease-in-out; /* Add this line */
  }
  
  .education .edu-box:hover div img  {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);    
  }
  
  .education .edu-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 5px 72px;
  }
  
  .education .edu-box p {
    font-size: 15px;
    color: #959595;
    margin-left: 72px;
  }
  
  .education .edu-box:hover {
    background-color: whitesmoke;
  }
  
  .education .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 200px;
  }
  
  .education .image-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    border-radius: 7px;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-img {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 200px;
  }
  
  .contact .info-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    border-radius: 7px;
    filter: hue-rotate(270deg) opacity(80%);
  }
  
  .contact .info {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .contact .info .info-box {
    margin-top: 25px;
    margin-left: 20px;
  }
  
  .contact .info .info-box i {
    font-size: 20px;
    color: #7cc576;
    float: left;
    width: 44px;
    height: 44px;
    background: #eaf6e9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info .info-box h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #151515;
  }
  
  .contact .info .info-box p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #484848;
  }
  
  .contact .info .info-box p span {
    color: #9ed49a;
  }
  
  .contact .info .info-box:last-child {
    margin-bottom: 25px;
  }
  
  .contact .info .info-box:hover i {
    background: #7cc576;
    color: #fff;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #fff;
    font-size: 14px;
    position: relative;
  }
  
  #footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: 1;
  }
  
  #footer .footer-bottom {
    border-top: 1px solid #222222;
    z-index: 2;
    position: relative;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  
  #footer .copyright {
    text-align: center;
  }
  
  #footer .version {
    text-align: center;
    opacity:30%;
    font-size:10px;
  }
  